@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  text-decoration: none; /* Asegúrate de que los enlaces no tengan subrayado por defecto */
}

a:hover {
  cursor: pointer;
  text-decoration: underline; /* Añade subrayado en hover */
}

.category-title {
  display: flex;
  align-items: center;
  text-align: center;
}

.category-title::before,
.category-title::after {
  content: "";
  flex: 1;
  border-bottom: 2px solid #dadada;
  margin: 0 1rem;
}
/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*/